export default {
  product: "Item",
  productActions: "Item Actions",
  editProduct: "Edit Item",
  addProduct: "Add Item",
  save: "Save",
  productList: "Item List",
  id: "ID",
  action: "Action",
  group: "Group",
  basicDetails: "Basic Details",
  otherDetails: "Other Details",
  displayText: "Display Text",
  financial: "Financial",
  resetFilter: "Reset filter",
  filters: "Filters",
  execute: "Execute",
  reset: "Reset",
  fieldView: "Field View",
  debitReport: "Item Report",
  selectFields: "Select Fields",
  confirm: "Confirm",
  cancel: "Cancel",
  selectAll: "Select All",
  excel: "Excel",
  update: "Update",
  customerPrice: "Customer price",
  supplierPrice: "Supplier Price",
  price: "Price",
  discount: "Discount %",
  customer: "Customer",
  addCustomerPrice: "Add Customer Price",
  addSupplierPrice: "Add Supplier Price",
  priceListGroup: "Price List Group",
  Quotation: "Quotation",
  Order: "Order",
  Delivery_Note: "Delivery_Note",
  Invoice: "Invoice",
  Credit_Invoice: "Credit_Invoice",
  Proforma_Invoice: "Proforma_Invoice",
  Receipt_Tax_Invoice: "Receipt Tax Invoice",
  Return_Certificate: "Return Certificate",
  message: {
    productSuccessfullyDeleted: "Item cancelled successfully",
    productSuccessfullyAdded: "Item added successfully",
    productSuccessfullyEdited: "Item edited successfully",
    pictureSuccessfullyDeleted: "Item image deleted successfully",
    pictureSuccessfullyAdded: "Item image added successfully",
    pictureSuccessfullyEdited: "Item image edited successfully",
    customerPriceSuccessfullyDeleted: "Customer price cancelled successfully",
    customerPriceSuccessfullyAdded: "Customer price added successfully",
    customerPriceSuccessfullyEdited: "Customer price edited successfully",
    supplierPriceSuccessfullyDeleted: "Supplier price cancelled successfully",
    supplierPriceSuccessfullyAdded: "Supplier price added successfully",
    supplierPriceSuccessfullyEdited: "Supplier price edited successfully",
    priceSuccessfullyAdded: "Price list added successfully",
    priceSuccessfullyEdited: "Price list edited successfully",
    priceSuccessfullyDeleted: "Price list cancelled successfully",
  },
  field: {
    itemCode: "Item code",
    itemDescription: "Item description",
    selectPicture: "Select picture",
    group: "Item group",
    foreignDescription: "Foreign description",
    barcode: "Barcode",
    lastPurchaseDate: "Last purchase date",
    lastPurchasePrice: "Last purchase price",
    lastPurchaseSupllier: "Last purchase supplier",
    lastSaleDate: "Last sale date",
    lastSalePrice: "Last sale price",
    lastSaleCustomer: "Last sale customer",
    purchasePrice: "Purchase price",
    salePrice: "Sale price",
    minimumSalePrice: "Minimum sale price",
    purchaseAccountingNo: "Purchase accounting no.",
    saleAccountingNo: "Sale accounting no.",
    itemWithoutVAT: "Item without VAT",
    createdAt: "Created At",
    updatedAt: "Updated At",
    stockItem: "Stock Item",
    vatAssetsItem: "VAT Assets item",
    vatRecognizedPercentage: "VAT recognized percentage",
    maximumVatRecogPercentage:
      "Maximum vat recognized percentage should be till 100 numbers ",
  },
};
